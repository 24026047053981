import _ from "lodash"
import React, {  } from "react"

import { Invention } from "../../inventions/InventionsProvider"
import { family_member, patent_family } from "../../data"
import { useRoles } from "../../user/Auth"
import { calculateClaimScopesByMemberId, useProductMapping } from "../../products/products"
import { Family, Member } from "../patents"
import { Commodity } from '../../products/products'
import { useInventions } from "../../inventions/InventionsProvider"
import { useFilteredPatents } from "../../filter/FilteredPatents"
import { useFilteredCommodities } from "../../filter/FilteredCommodities"
import { usePatents } from "../PatentsProvider"
import { useLocalState } from "../../settings/localStorage"
import { useClaims } from "../../claims/ClaimsProvider"

export const byFamilyMember = "by-family-member"
export const byPatentFamily = "by-patent-family"
export const byClaimScopes = "by-claim-scopes"
export const byProduct = "by-product"
export const byInvention = "by-invention"


export interface Row {
    families: Family[];
    members: Member[];
    products: Commodity[];
    claimScopeIds: number[];
    inventions: Invention[];
    key: string | number;
}

function useRows(groupBy: string): Row[] {
    const { hasClaimScopes } = useRoles()
    const { memberById } = usePatents()
    const { claims } = useClaims()
    const { families, familyById, membersByFamilyId } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()
    const { claimScopeCommoditiesByPatentFamilyId, commoditiesByFamilyId, membersByCommodityId, commoditiesByMemberId, claimScopeCommoditiesByFamilyMemberId, claimScopeMembersByCommodityId } = useProductMapping()
    const { inventions, inventionsByMemberId } = useInventions()
    //console.log({claimScopeCommoditiesByFamilyMemberId, commoditiesByMemberId})
    //console.log({claimScopeCommoditiesByPatentFamilyId})

    function inventionsByMembers(members: {familyMemberId?: number}[]) {
        return _(members)
            .flatMap(m => inventionsByMemberId[m.familyMemberId] ?? [])
            .unionBy(i => i.inventionId)
            .value()
    }

    // TODO: sort all families/members by internal reference
    const claimScopesByMemberId = hasClaimScopes
        ? calculateClaimScopesByMemberId(claims)
        : {}

    switch(groupBy) {
        case byFamilyMember:
        case family_member:
            return _(membersByFamilyId)
                .toPairs()
                .filter(([familyId]) => familyById[familyId] !== undefined)
                .sortBy(([familyId]) => familyById[familyId].internalReference) // TODO: needed?
                .flatMap(([familyId, members]) => {
                    return members.map(m => ({
                        families: [familyById[familyId]],
                        members: [m],
                        products: ((hasClaimScopes ? claimScopeCommoditiesByFamilyMemberId : {})[m.familyMemberId] 
                            ?? commoditiesByMemberId[m.familyMemberId]) ?? [],
                        claimScopeIds: claimScopesByMemberId[m.familyMemberId] ?? [],
                        inventions: inventionsByMemberId[m.familyMemberId] ?? [],
                        key: m.internalReference,
                    }))
                }).value()
        case byPatentFamily:
        case patent_family:
            return _(families)
                .sortBy(f => f.internalReference)
                .map(f => {
                    const members = membersByFamilyId[f.patentFamilyId] ?? []
                    return {
                        families: [f],
                        members,
                        // This can have undefined. Why?
                        products: ((hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {})[f.patentFamilyId] 
                            ?? commoditiesByFamilyId[f.patentFamilyId]) ?? [],
                        claimScopeIds: _(members).flatMap(m => claimScopesByMemberId[m.familyMemberId] ?? []).uniq().value(),
                        inventions: inventionsByMembers(members),
                        key: f.internalReference,
                    }
                }).value()
        
        case byClaimScopes:
        case 'claimScope':
            return _(membersByFamilyId).toPairs().flatMap(([familyId, members]) => {
                const families = [familyById[familyId]]
                return _(members)
                    .map(({familyMemberId}) => ({familyMemberId, claimScopes: claimScopesByMemberId[familyMemberId] ?? []}))
                    .groupBy(({claimScopes}) => claimScopes.join('-'))
                    .toPairs()
                    .map(([key, value]) => ({
                        families,
                        members: value.map(({familyMemberId}) => memberById[familyMemberId]) ?? [],
                        products: claimScopeCommoditiesByFamilyMemberId[value[0]?.familyMemberId] ?? [],
                        claimScopeIds: _(value).flatMap(({claimScopes}) => claimScopes).uniq().value(),
                        inventions: inventionsByMembers(value),
                        key: `${familyId}-${key}`,
                    }))
                    .value()
            }).value()
        case byProduct:
            return _(commodities)
                .map(c => {
                    const _members = ((hasClaimScopes ? claimScopeMembersByCommodityId : {})[c.commodityId] 
                        ?? membersByCommodityId[c.commodityId]) ?? []
                    const members = _(_members).filter(m => m.patentFamilyId in familyById).value()
                    const families = _(members).uniqBy(m => m.patentFamilyId).map(m => familyById[m.patentFamilyId]).value()
                    //console.log({c, families})
                    return {
                        families,
                        members,
                        products: [c],
                        claimScopeIds: _(members).flatMap(m => claimScopesByMemberId[m.familyMemberId]).uniq().value(),
                        inventions: inventionsByMembers(members),
                        key: c.commodityId,
                    }
                })
                .value()
        case byInvention: 
            return _(inventions)
                .map(invention => {
                    const member = memberById[invention.familyMemberId]
                    const members = member ? [member] : []
                    const families = _(members).map(m => familyById[m.patentFamilyId]).filter(Boolean).value()
                    return {
                        families,
                        members,
                        inventions: [invention],
                        products: ((hasClaimScopes ? claimScopeCommoditiesByFamilyMemberId : {})[member?.familyMemberId] 
                        ?? commoditiesByMemberId[member?.familyMemberId]) ?? [],
                        claimScopeIds: _(members).flatMap(m => claimScopesByMemberId[m.familyMemberId]).uniq().value(),
                        key: invention.inventionId,
                    }
                })
                .value()
        default:
            console.warn(`Not handling ${groupBy}`)
            return []
    }
}


const RowsContext = React.createContext({
    rows: [] as Row[],
    groupBy: byFamilyMember,
    setGroupBy: (groupBy: string) => {},
    //groupableFields: [byFamilyMember, byPatentFamily, byProduct, byInvention],
    searchFields: [] as string[],
    setSearchFields: (searchFields: string[]) => {},
    sortOrder: 1,
    setSortOrder: (sortOrder: number) => {},
    sortField: '',
    setSortField: (sortField: string) => {},
})

export function useRowsProvider() {
    return React.useContext(RowsContext)
}

export function RowsProvider({children}: {children: React.ReactNode}) {
    //console.log('recalculating RP')

    const [groupBy, setGroupBy] = useLocalState('analytics-groupBy', byFamilyMember)
    const [searchFields, setSearchFields] = useLocalState('analytics-fields', [])
    const [sortOrder, setSortOrder] = useLocalState('analytics-sort-order', +1)
    const [sortField, setSortField] = useLocalState('analytics-sort-field', '')

    const rows = useRows(groupBy)

    const value = {
        rows,
        //groupableFields,
        groupBy, setGroupBy,
        searchFields, setSearchFields,
        sortOrder, setSortOrder,
        sortField, setSortField,
    }

    return (
        <RowsContext.Provider {...{value}}>
            {children}
        </RowsContext.Provider>
    )
}

export function useGroupableFields() {
    const {hasClaimScopes} = useRoles()

    const groupableFields = [ 
        byFamilyMember, 
        byPatentFamily,  
        hasClaimScopes && byClaimScopes, 
        byProduct, 
        byInvention,
    ].filter(Boolean)

    return groupableFields
}

