import { Bar, BarChart, ResponsiveContainer, Treemap, Tooltip, XAxis, YAxis, PieChart, Pie, Cell, ReferenceLine } from 'recharts'
import { ChartBarIcon, Squares2X2Icon } from '@heroicons/react/24/outline'
import { PureComponent, useState } from 'react'
import _ from 'lodash'
import { colors } from '../data'
import { useTranslation } from 'react-i18next'
import { useTasks } from '../tasks/TasksProvider'
import { usePatents } from '../patents/PatentsProvider'
import { useFilteredTrademarks } from '../filter/FilteredTrademarks'
import { useUserSettings } from '../user/UserSettingsProvider'
import { augment } from '../tasks/utils'
import { Link } from 'react-router-dom'
import ToggleButton from '../components/ToggleButton'
import { DueDateBadge } from '../tasks/Tasks'
import { nonEmptyString } from '../utils/strings'
import { aggregateOverYears } from './expiries'

export function ChartContainer({children, className = ''}) {
    return <div className={`min-w-full max-w-full sm:min-w-xs sm:max-w-xs xl:min-w-sm xl:max-w-sm p-4 bg-white rounded-xl shadow-sm ${className}`}>
        {children}
    </div>
}

export function BasicProtectionChart({protectionCounts, protectionColors}) {
    const existingCounts = new Set(_(protectionCounts).map(c => parseInt(c.protectionCount)).value())
    //console.log({existingCounts, protectionColors})
    return (
        <div className="flex flex-row items-center gap-6">
            <div className="w-40 h-40 shrink-0">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={100} height={100} >
                        <Pie 
                            data={protectionCounts} dataKey="count" nameKey="name" 
                            label={protectionChartLabels} labelLine={false}
                            cx="50%" cy="50%" outerRadius="100%" startAngle={90} endAngle={450}
                        >
                            {protectionCounts.map((entry, index) => <Cell key={`cell-${index}`} fill={protectionColors[entry.protectionCount].color} />)}
                        </Pie>
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<PieChartTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div>
                {_(protectionColors)
                    .map(({ protectionCount, name, color, displayName }) =>
                        existingCounts.has(protectionCount) &&
                        <div key={name} className="flex flex-row gap-2 mb-1 items-start">
                            <div className="w-3 h-3 mt-1 shrink-0 rounded-sm" style={{ backgroundColor: color }}></div>
                            <p className="text-xs xl:text-sm text-slate-700">{displayName}</p>
                        </div>)
                    .value()}
            </div>
        </div>
    )
}

const RADIAN = Math.PI / 180;
const protectionChartLabels = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, protectionCount, labelColor,}) => {

    //console.log({index, percent, cx, cy, protectionCount})
    //console.log({props})
    if (percent < 0.10) return null

    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const color = labelColor ?? (index < 2 && protectionCount < 2 ? colors["pcx-800"] : colors["pcx-100"])

    return (
        <text x={x} y={y} fill={color} textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export function StatusChart({ data, statusColors }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={100} height={100}>
        <Pie data={data} dataKey="count" nameKey="name" cx="50%" cy="50%" outerRadius="100%" label={statusChartLabels} labelLine={false}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={statusColors[entry.name]} />
          ))}
        </Pie>
        {/* @ts-ignore */}
        <Tooltip wrapperStyle={{ outline: 'none' }} content={<PieChartTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  )
}

const statusChartLabels = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, ...props }) => {
    //console.log({props, index})
    if (percent < 0.10) return null

    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const color = name === 'in-preparation' ? colors['pcx-800'] : colors['pcx-100']

    return (
        <text x={x} y={y} fill={color} textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
}

function PieChartTooltip({active, label, payload}) {
    //console.log({payload, label, props})
    if (active)
        return <div className="bg-white p-2 pb-0 rounded-lg border border-pcx-400">
            <p className="whitespace-nowrap">{payload[0].payload.displayName}: {payload[0].value}</p>
        </div>
    else
        return null
}

export function TopXMarker({count, max = 5}: {count: number, max?: number}) {
    const {t} = useTranslation()
    if (count <= max) return null
    return <span className="text-slate-500">({t('cockpit-page.top-x-of-total', {count: max, total: count})})</span>
}

export function BarTreemapToggle({showBars, setShowBars}) {
    return (
        <button className="text-pcx-600" onClick={() => setShowBars(!showBars)}>
            {showBars
                ? <Squares2X2Icon className="h-6 w-6" />
                : <ChartBarIcon className="h-6 w-6 rotate-90 -scale-x-100" />}
        </button>
    )
}

export function CountryHistogram({ data, showBars }: { data: { name: string; count: number }[]; showBars: boolean }) {

  return data.length > 0 ? (
    <ResponsiveContainer className="text-xs" width="100%" height={250}>
      {showBars ? (
        <BarChart layout="vertical" data={data.slice(0, 10)} margin={{ left: 0, top: 5, right: 0, bottom: -10 }}>
          <YAxis type="category" dataKey="name" hide={true} />
          <XAxis type="number" allowDecimals={false} />
          {/* @ts-ignore */}
          <Tooltip wrapperStyle={{ outline: 'none' }} content={<HistogramTooltip />} />
          <Bar
            dataKey="count"
            name="Countries"
            fill={colors['pcx-300']}
            label={({ x, y, height, value, name, width }) => {
              if (width < 32) return null
              const y_offset = height / 2 - 12 / 2 + 1 // 12 is the font size
              return (
                <text x={x + 5} y={y + height - y_offset} fill={colors['pcx-900']}>
                  {name}: {value}
                </text>
              )
            }}
          />
        </BarChart>
      ) : (
        <Treemap
          animationDuration={500}
          data={data}
          dataKey="count"
          // @ts-ignore
          content={<CountryArea colors={sortedColors} textColors={textColors} />}
        >
          {/* @ts-ignore */}
          <Tooltip wrapperStyle={{ outline: 'none' }} content={<TreemapTooltip />} />
        </Treemap>
      )}
    </ResponsiveContainer>
  ) : null
}

export function CostDriverChart({data, showBars}: {data: {name: string, total: number}[], showBars: boolean}) {
    return (
      <ResponsiveContainer className="text-xs" width="100%" height={250}>
        {showBars ? (
          <BarChart layout="vertical" data={data.slice(0, 10)} margin={{ left: 0, top: 5, right: 0, bottom: -10 }}>
            <YAxis type="category" dataKey="name" hide={true} />
            <XAxis type="number" allowDecimals={false} />
            {/* @ts-ignore */}
            <Tooltip wrapperStyle={{ outline: 'none' }} content={<HistogramTooltip />} />
            <Bar
              dataKey="total"
              name="Costs"
              fill={colors['pcx-300']}
              label={({ x, y, height, value, name, width }) => {
                //if (width < 32)
                //    return null
                //console.log({name, width})
                const y_offset = height / 2 - 12 / 2 + 1 // 12 is the font size
                return (
                  <text x={x + 5} y={y + height - y_offset} fill={colors['pcx-900']}>
                    {name}: {value}
                  </text>
                )
              }}
            />
          </BarChart>
        ) : (
          <Treemap
            animationDuration={500}
            data={data}
            dataKey="total"
            // @ts-ignore
            content={<CountryArea colors={sortedColors} textColors={textColors} />}
          >
            {/* @ts-ignore */}
            <Tooltip wrapperStyle={{ outline: 'none' }} content={<TreemapTooltip />} />
          </Treemap>
        )}
      </ResponsiveContainer>
    )
}

export function HistogramTooltip({active, label, payload, lineColor}) {
    //console.log({payload})
    if (active && payload?.length > 1)
        return <div className="bg-white p-2 pb-0 rounded-lg border border-pcx-400">
            <p>{label}: {payload[0].value}</p>
            {lineColor && <p>Total: {payload[1].value}</p>}
        </div>
    else
        return null
}

export class CountryArea extends PureComponent {
    render() {
      // @ts-ignore
      const { root, value, depth, x, y, width, height, colors, textColors, name } = this.props;

      if (!_.isArray(root.children)) return null

      // Higher rank means more area
      const maxValue: number = _.max(root.children.map((c: {value: number}) => c.value))
      const rank = (value / maxValue) * (root.children.length - 1) 

      const colorIndex = Math.floor((rank / (root.children.length / colors.length)))
      const textIndex = Math.floor((rank / (root.children.length / textColors.length)))

      //console.log({rank, N: root.children.length , B: textColors.length})
      //console.log({name, width, height})
      //console.log(this.props)

      const minTextWidth = Math.max(20, (name?.length ?? 2) * 8)
  
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: depth < 2 ? colors[colorIndex] : '#ffffff00',
              stroke: '#fff',
              strokeWidth: 2 / (depth + 1e-10),
              strokeOpacity: 1 / (depth + 1e-10),
            }}
          />
          {depth === 1 && height > 14 && width > minTextWidth ? ( // To small boxes don't fit the text
            <text 
                x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" 
                fill={depth < 2 ? textColors[textIndex] : "#fff"} 
                fontSize={14}
            >
              {name}
            </text>
          ) : null}
        </g>
      );
    }
  }

export const TreemapTooltip = ({active, label, payload}) => {
    if (active)
        return <div className="bg-white p-2 pb-0 rounded-lg border border-pcx-400">
            <p>{payload[0].payload.name}: {payload[0].value}</p>
        </div>
    else
        return null
}

export const sortedColors = _(colors).toPairs().sortBy(([key]) => key).map(([key, color]) => color).value()
export const textColors = [colors['pcx-900'], colors['pcx-900'], colors['pcx-100'], colors['pcx-100'], colors['pcx-100'], colors['pcx-100']]


export function TasksCard() {
    const {t} = useTranslation()
    const {tasks} = useTasks()
    const {familyById, memberById} = usePatents()
    const {trademarkById} = useFilteredTrademarks()
    const {users, userSettings} = useUserSettings()
    const user = userSettings?.user
    const [onlyMine, setOnlyMine] = useState(false)

    const nextTasks = _(tasks)
        .filter(t => !t.done)
        .filter(t => !onlyMine || t.assignedTo === user)
        .map(task => ({...task, date: _([task.dueDate, task.internalDueDate]).filter(Boolean).sortBy().first()}))
        .sortBy('date')
        .take(3)
        .map(task => ({...task, ...augment(task, familyById, memberById, trademarkById, users)}))
        .value()

    const today = new Date()

    return <ChartContainer>
        <div className="mb-4 flex flex-row gap-2 justify-between items-end">
            <h2>
                <Link to="/tasks">{t('next-tasks')}</Link>
            </h2>
            <label className="text-sm text-slate-700 inline-flex gap-2">
                {t('only-mine')} <ToggleButton checked={onlyMine} setChecked={setOnlyMine} />
            </label>
        </div>
        {nextTasks.map(task =>
            <Link 
                key={task.taskId} to={task.to ?? '/tasks'}
                className="block rounded-lg shadow-sm border border-pcx-200 mt-2 "
            >
                <div title={task.title} className="text-sm flex flex-row items-center gap-1 px-2 py-1 bg-pcx-100">
                    <h3 className="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap grow text-base">{task.title}</h3>
                    <DueDateBadge {...{...task, today}} />
                    <div className="tabular-nums whitespace-nowrap">{task.date}</div>
                </div>
                {nonEmptyString(task.comment) && 
                    <div className="p-2 text-sm text-slate-700 line-clamp-1 relative">
                        {task.comment}
                        <div className="absolute bottom-0 bg-white/50 h-2 w-[95%]"/>
                    </div>}
            </Link>)}
    </ChartContainer>
}

export function ActiveIpRightsChart({members}: {members: {start: string, end: string}[]}) {
  const {t} = useTranslation()

  const today = new Date().toISOString().slice(0, 10)
  const aggregated = aggregateOverYears(members, today)

  // const stoppedColor = 'rgb(254, 202, 202)'
  const stoppedColor = '#f87171'
  //const stoppedColor = '#c2410c' //colors['pcx-900'], },

  const dataFormatting = [
      { dateKey: 'active', color: colors['pcx-500'], },
      { dateKey: 'started', color: colors['pcx-300'], },
      { dateKey: 'stopped', color: stoppedColor }
  ].map(d => ({...d, name: t(d.dateKey)}))

  return <>
    <ResponsiveContainer className='text-sm' width="100%" height={180}>
        <BarChart
            width={500} height={300}
            margin={{left:-25}}
            stackOffset="sign"
            data={aggregated.map(a => ({ ...a, stopped: - a.stopped }))}
        >
            <XAxis dataKey="year"/>
            <YAxis />
            <Tooltip  />
            {dataFormatting.map(({dateKey, color, name}) => <Bar key={dateKey} dataKey={dateKey} stackId="a" fill={color} name={name} />)}
            <ReferenceLine y={0} stroke="#555" />
        </BarChart>
    </ResponsiveContainer>
    <div className="flex flex-row gap-4 justify-end">
        {dataFormatting.map(({ dateKey, color, name }) =>
            <div key={dateKey} className="flex flex-row gap-1.5 items-center">
                <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }}></div>
                <div className="text-xs xl:text-sm  text-slate-700">{name}</div>
            </div>)}
    </div>
  </>
}