import { Member } from "../../patents/patents";
import { useDennemeyer, useIpRight } from "../DennemeyerProvider";
import { renewalStatusExtraction } from "./modern_states";

export default function useUpIpRight(member: Member) {

    const {upIpRightByMemberId, validationsByIpRightId} = useDennemeyer()

    const upRight = upIpRightByMemberId[member.familyMemberId]

    const {ipRight: ipRightResp, isLoading} = useIpRight(upRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    const renewalStatus = renewalStatusExtraction({ member, pcIpRight: upRight, ipRight, validationErrors: validationsByIpRightId[upRight?.ipRightId] })

    return {upRight, renewalStatus, ipRight, isLoading}
}