import { Fragment } from "react"
import { Link, Outlet } from "react-router-dom"
import _ from 'lodash'

import { PlainImage } from "../components/Image"
import { IconEdit } from "../components/icons"
import { groupByEp, memberUrl } from "../patents/utils"
import { useAllOrFilteredPatents } from "../filter/FilteredPatents"
import clsx from "clsx"
import { useClaims } from "./ClaimsProvider"
import { useTranslation } from "react-i18next"
import { useRoles } from "../user/Auth"
import ProtectionIcon from "../components/ProtectionIcon"
import { commodityName, commodityUrl } from "../products/products"
import { useProducts } from "../products/ProductsProvider"
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"


// - Create the mapping (memberId, version, claimScopeId) -> claim
// - Create selected versions
// - relevant scope ids <- all (memberId, version, *) for which (memberId, version) in selected versions

function ClaimScopeDescription({claimScopeId, claimScopeSummary}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {commodityClaimScopeLinks, commodityById} = useProducts()

    const unclassified = t('unclassified')
    const products = _(commodityClaimScopeLinks)
        .filter(c => c.claimScopeId === claimScopeId && c.protected)
        .map(c => commodityById[c.commodityId])
        .filter(Boolean)
        .uniqBy('commodityId')
        .map(c => ({ ...c, commodityName: commodityName(c, unclassified) }))
        .sortBy(c => c.commodityName.toLowerCase())
        .value()

    return (
        <div className="p-4 max-w-md bg-pc-100 dark:bg-pc-200 rounded-lg text-sm leading-relaxed h-full">
            <div className="w-64 h-48 pb-2 mx-auto">
                <PlainImage {...{ entity: "claim-scope", entityId: claimScopeId, text: claimScopeSummary }} />
            </div>
            <div dangerouslySetInnerHTML={{__html: claimScopeSummary}} />
            <div className="py-3">
                <div className="flex flex-row items-center gap-2 justify-between">
                    <h4 className="text-sm font-semibold">{t('products')}</h4>
                    {isEditUser && <Link to={`products/${claimScopeId}`} className="btn-secondary p-px">{<IconEdit className="size-3" />}</Link>}
                </div>
                <ul className="list-none text-slate-700 pl-0 grow">
                    <li className="hidden last:block text-slate-500">{t('no-affected-products')}</li>
                    {products.map(({ commodityId, isThirdParty, commodityName }, i) =>
                        <li key={commodityId} className="flex flex-row items-center gap-2">
                            <ProtectionIcon {...{ isThirdParty }} /> <Link to={commodityUrl(products[i])}>{commodityName}</Link>
                        </li>)}
                </ul>
            </div>
        </div>
    )
}

// TODO: delete the rest
export function ClaimScape2({patentFamilyId, applyGlobalFilter = false, onlyIndependentClaims = false, showClaims = false}) {
    const {membersByFamilyId} = useAllOrFilteredPatents(applyGlobalFilter)
    
    const { claims, claimScopes } = useClaims()
    const members = membersByFamilyId[patentFamilyId] ?? []
    const familyMembers = groupByEp(members)

    const memberIds = new Set(familyMembers.map(m => m.familyMemberId))
    const memberById = _.keyBy(familyMembers, m => m.familyMemberId)

    // [familyMemberId]
    const familyClaims = _(claims)
        .filter(c => !onlyIndependentClaims || c.claimType === 'independent-claim')
        .filter(c => memberIds.has(c.familyMemberId))
        .groupBy(c => c.familyMemberId)
        .mapValues(cs => {
            const maxDate = _(cs).map(c => c.versionDate).max()
            const maxVersion = _(cs).filter(c => c.versionDate === maxDate).map(c => c.version).max()
            return cs.filter(c => c.version === maxVersion)
        })
        .value()

    // [claimScopeId][familyMemberId]
    const claimsByScopeId = _(familyClaims).toPairs()
        .flatMap(([id, cs]) => cs.filter(c => c.claimScopeId))
        .groupBy(c => c.claimScopeId)
        .mapValues(cs => _.groupBy(cs, c => c.familyMemberId))
        .value()

    const claimScopeIds = new Set(
        _(familyClaims).toPairs()
            .flatMap(([id, cs]) => cs.map(c => c.claimScopeId).filter(c => c !== undefined))
            .value())
    //console.log(claimByScopeId)

    const familyScopes = _(claimScopes)
        .filter(c => claimScopeIds.has(c.claimScopeId))
        .sortBy(c => -_.size(claimsByScopeId[c.claimScopeId]))
        .value()

    // console.log({familyScopes, claimsByScopeId})
    return <>
        <table>
            <tbody>
                {familyMembers.map(m =>
                    <tr key={m.internalReference}>
                        <td className="whitespace-nowrap py-1 pr-1 align-top">
                            <Link className="text-pc-600 underline" to={memberUrl(m)}>{m.internalReference}</Link>
                        </td>
                        {familyScopes.map(({ claimScopeId }, scopeIndex) => {
                            const claims = claimsByScopeId[claimScopeId]?.[m.familyMemberId]
                            const internalReference = memberById[claims?.[0]?.familyMemberId]?.internalReference
                            return <td key={`${m.internalReference}--${claimScopeId}`} className={clsx("p-px align-top", showClaims && "even:bg-pcx-100 even:empty:bg-transparent")}>
                                {showClaims
                                ? <FullClaim {...{claims, internalReference}} />
                                : <ClaimNumberTooltip {...{ claims, internalReference }} />}
                            </td>
                        })}
                    </tr>)}
                <tr>
                    <td></td>
                    {familyScopes.map(({ claimScopeId, claimScopeSummary }) =>
                        <td
                            className="p-px align-top"
                            key={'claim-scope-' + claimScopeId}
                        >
                            <ClaimScopeDescription {...{
                                claimScopeId,
                                claimScopeSummary,
                            }} />
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
        <Outlet />
    </>
}

function FullClaim({claims = [], internalReference }) {
    if (!claims) return null
    return (
      <div className="px-1 py-0.5">
        {claims.map(({ claimNumber, claimText }) => (
          <Fragment key={claimNumber}>
            <h5 className="pb-1">Claim {claimNumber} of {internalReference}</h5>
            <div className="mb-2 text-slate-600 hover:text-slate-900" dangerouslySetInnerHTML={{ __html: claimText }} />
          </Fragment>
        ))}
      </div>
    )
}

function ClaimNumberTooltip({claims, internalReference}) {
    if (claims)
        return (
        <div className="ribbon rounded-sm block">
            {claims.map(({ claimNumber, claimText}) => 
            <Popover key={claimNumber} as="span" className="relative group after:content-[';'] mr-1 last:after:content-['']">
                <PopoverButton>
                <Link to={memberUrl({ internalReference })} className="underline ">Claim {claimNumber}</Link>
                </PopoverButton>
                <PopoverPanel static={true} className="absolute hidden group-hover:block w-96 p-4 bg-white rounded-lg shadow-lg border border-pcx-200 z-10">
                    <h5 className="pb-2">Claim {claimNumber} of {internalReference}</h5>
                    <div dangerouslySetInnerHTML={{ __html: claimText }} />
                </PopoverPanel>
            </Popover>
            )}
        </div>
        )
    else
        return null
}
