import _ from "lodash"

export function sumUp(a: Record<string, number>, b: Record<string, number>) {
    const allKeys = new Set([...Object.keys(a), ...Object.keys(b)])
    return Object.fromEntries([...allKeys].map(k => [k, (a[k] ?? 0) + (b[k] ?? 0)] as [string, number]))
}

export function calculateTotalRows(rows: {columns: Record<string, number>[]}[], columnKeys: string[], aggregations: Record<string, AggregationType> = {}) {
    const total = {}

    for (const row of rows) {
        for (let col = 0; col < row.columns.length; col++) {
            const agg = aggregations[columnKeys[col]] ?? 'sum'
            if (agg === 'total') {
                total[columnKeys[col]] = sumUp(total[columnKeys[col]] ?? {}, {'Total': _(row.columns[col] ?? {}).values().sum()})
            } else {
                total[columnKeys[col]] = sumUp(total[columnKeys[col]] ?? {}, row.columns[col] ?? {})
            }
    }
    }

    return total
}

export type AggregationType = 'sum' | 'total' // maybe per row; e.g. for owner etc.?