import { Invention } from "../../inventions/InventionsProvider"
import { Family, Member } from "../../patents/patents"
import { Commodity } from "../../products/products"
import { IpRight, PcIpRight, ValidationMessage } from "../../renewal/DennemeyerProvider"
import { canBeImported, canBeStopped } from "../../renewal/state/modern_states"
import { Trademark, TrademarkFamily } from "../../trademarks/TrademarksProvider"

export type ManualUpdateAction = 'initiate-payment-handling' | 'stop-payment-handling'
export type AutomaticUpdateAction = | 'move-folder' | 'update-reference'
export type AfterUpdateAction = ManualUpdateAction | AutomaticUpdateAction

export const afterUpdateActionsStr = 'after-update-actions'

export interface CreateChangedStateArgs { 
    oldMember?: Member, 
    newMember: Member,
    pcIpRight?: PcIpRight,
    ipRight?: IpRight,
    validationErrors?: ValidationMessage[],
    hasAnnuities: boolean, 
    hasDocuments: boolean, 
}

export type ChangedState<T extends Member | Family | Invention | Commodity | Trademark | TrademarkFamily> = {
    [afterUpdateActionsStr]: AfterUpdateAction[],
    from?: T,
    to: T,
}

export function createChangedMemberState({ hasAnnuities, hasDocuments, oldMember, newMember, pcIpRight, ipRight, validationErrors }: CreateChangedStateArgs): ChangedState<Member> | {} {

    const referenceChanged = (oldMember !== undefined) && (oldMember?.internalReference !== newMember?.internalReference)
    const afterUpdateActions = [
        hasAnnuities && 
            canBeStopped({ oldMember, newMember, pcIpRight, ipRight, validationErrors }) ?
                'stop-payment-handling' :
            canBeImported({ oldMember, newMember, pcIpRight, ipRight, validationErrors }) ?
                'initiate-payment-handling' :
                undefined,
        hasAnnuities && referenceChanged ? 'update-reference' : undefined,
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldMember,
        to: newMember,
    }
}

export function createChangedFamilyState({hasDocuments, oldFamily, newFamily}: {hasDocuments: boolean, oldFamily?: Family, newFamily: Family}): ChangedState<Family> | {} {
    const referenceChanged = oldFamily !== undefined && oldFamily.internalReference !== newFamily.internalReference

    const afterUpdateActions = [
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldFamily,
        to: newFamily,
    }
}

export function createChangedProductState({hasDocuments, oldProduct, newProduct}: {hasDocuments: boolean, oldProduct: Commodity, newProduct: Commodity}) {
    const referenceChanged = oldProduct !== undefined && oldProduct.commodityReference !== newProduct.commodityReference

    const afterUpdateActions = [
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldProduct,
        to: newProduct,
    }
}

export function createChangedTrademarkState({hasDocuments, oldTrademark, newTrademark}: {hasDocuments: boolean, oldTrademark: Trademark, newTrademark: Trademark}) {
    const referenceChanged = oldTrademark !== undefined && oldTrademark.reference !== newTrademark.reference

    const afterUpdateActions = [
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldTrademark,
        to: newTrademark,
    }
}

export function createChangedTrademarkFamilyState({hasDocuments, oldTrademarkFamily, newTrademarkFamily}: {hasDocuments: boolean, oldTrademarkFamily?: TrademarkFamily, newTrademarkFamily: TrademarkFamily}): ChangedState<TrademarkFamily> | {} {
    const referenceChanged = oldTrademarkFamily !== undefined && oldTrademarkFamily.reference !== newTrademarkFamily.reference

    const afterUpdateActions = [
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldTrademarkFamily,
        to: newTrademarkFamily,
    }
}

export function createChangedInventionState({hasDocuments, oldInvention, newInvention}: {hasDocuments: boolean, oldInvention?: Invention, newInvention: Invention}): ChangedState<Invention> | {} {
    const referenceChanged = oldInvention !== undefined && oldInvention.reference !== newInvention.reference

    const afterUpdateActions = [
        hasDocuments && referenceChanged ? 'move-folder' : undefined,
    ].filter(Boolean) as AfterUpdateAction[]

    if (afterUpdateActions.length === 0)
        return {}

    return {
        [afterUpdateActionsStr]: afterUpdateActions,
        from: oldInvention,
        to: newInvention,
    }
}