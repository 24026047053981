import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";

import { Trademark, useTrademarks } from "./TrademarksProvider";
import { Comment } from "../comments/Comments";
import { trade_mark, trademark_family } from "../data";
import { useComments, Comment as CommentProps } from "../comments/CommentsProvider";
import { useRoles } from "../user/Auth";
import { trademarkUrl } from "./utils";
import { IconChevronDown } from "../components/icons";
import clsx from "clsx";
import _ from "lodash";

export default function TrademarkComments({trademarkId, familyId}: {
    trademarkId?: number;
    familyId?: number;
}) {
    const {trademarkById, trademarkFamilyById, trademarksByFamilyId} = useTrademarks()
    const {commentsLookUp} = useComments()

    const family = trademarkFamilyById[familyId]
    const trademark = trademarkId && trademarkById[trademarkId]

    const trademarks = _(trademarksByFamilyId[familyId] ?? [])
        .sortBy(tm => tm.reference)
        .value()

    return (
        <div className="flex flex-col gap-2">
            {familyId !== undefined 
                ? <TmEntityComments entity={trademark_family} entityId={familyId} comments={commentsLookUp[trademark_family]?.[familyId] ?? []} name={family.reference} />
                : <TmEntityComments entity={trade_mark} entityId={trademark.trademarkId} comments={commentsLookUp[trade_mark]?.[trademark.trademarkId] ?? []} name={trademark.reference} />
            }
            <div className="space-y-4">
                {trademarks.map(tm => <CollapsableComments key={tm.trademarkId} trademark={tm} />)}
            </div>
        </div>
    )
}

function TmEntityComments({
  entity,
  entityId,
  comments,
  name,
}: {
  name: string
  entity: string
  entityId: number
  comments: CommentProps[]
}) {
  const { t } = useTranslation()
  const { isEditUser } = useRoles()

  const addButtonStyle = 'btn-secondary bg-white text-sm whitespace-nowrap min-w-fit py-0.5'

    return (
      <div>
        <div className="flex flex-row pb-4 px-1">
          <h3 className="grow">{t('comments')}</h3>
          {isEditUser && (
            <Link title={t('add-comment')} to={`comments?entity=${entity}&entityId=${entityId}&name=${name}`} className={addButtonStyle}>
              {t('add')}
            </Link>
          )}
        </div>
        <div className="space-y-3">
          {comments.map((c, ci) => (
            <Comment key={ci} {...c} />
          ))}
        </div>
      </div>
    )
}

function CollapsableComments({trademark}: {trademark: Trademark}) {
    const {commentsLookUp} = useComments()

    if(!trademark) return null

    const comments = commentsLookUp[trade_mark]?.[trademark?.trademarkId] ?? []

    if (comments.length === 0) return null

    return (
      <Disclosure as="div" className="mt-2 flex flex-col gap-2">
        {({ open }) => (
          <>
            <DisclosureButton className="flex flex-row justify-between items-center px-2 py-1 bg-pcx-100 hover:bg-pcx-200 text-pcx-600 rounded">
              <Link className="hover:underline" to={trademarkUrl(trademark)}>
                <h5 className="text-pcx-600">{trademark.reference}</h5>
              </Link>
              <IconChevronDown className={clsx('h-5 w-5', open && 'transform rotate-180')} />
            </DisclosureButton>
            <DisclosurePanel>
              {comments.map(c => (
                <Comment key={`comment-${c.commentId}`} {...c} />
              ))}
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    )
}