import React, {useContext} from "react";
import _ from 'lodash'

import { useBackend } from "../BackendProvider";
import { evaluation_link, evaluation_meta_link } from "../data";
import { Family, Member } from "../patents/patents";
import { useTranslation } from "react-i18next";
import { usePatents } from "../patents/PatentsProvider";
import { createExcelFile } from "../backend";
import { useAuth } from "../user/Auth";

// score = {familyMemberId, type, score}

export type Score = {
    familyMemberId: number;
    type: string;
    score: number;
}

export type ValuationMeta = {
    familyMemberId: number;
    evaluationDate: string;
}

export type ValuationsContextProps = {
    scores: Score[];
    scoresLookup: Record<number, Score[]>;
    addScores: (scores: Score[]) => Promise<void>;
    deleteScores: (scores: Score[]) => Promise<void>;

    valuationMeta: ValuationMeta[];
    addValuationMetas: (valuationMetas: ValuationMeta[]) => Promise<void>;
    deleteValuationMetas: (valuationMetas: ValuationMeta[]) => Promise<void>;

    loadAll: () => Promise<void>;
}


const ValuationsContext = React.createContext({
    scores: [] as Score[],
    scoresLookup: {} as Record<number, Score[]>,
    addScores: (ss: Score[]) => Promise.resolve({}),
    deleteScores: (ss: Score[]) => Promise.resolve({}),
    
    valuationMeta: [] as ValuationMeta[],
    valuationMetaById: {} as Record<number, ValuationMeta>,
    addValuationMetas: (ms: ValuationMeta[]) => Promise.resolve({}),
    deleteValuationMetas: (ms: ValuationMeta[]) => Promise.resolve({}),

    loadAll: () => Promise.resolve({})
})

export default function ValuationsProvider({children}) {
    const { evaluations, evaluationMetas, linkOperation } = useBackend()

    const scoresLookup = _.groupBy(evaluations, e => e.familyMemberId)
    const valuationMetaById = _.keyBy(evaluationMetas, m => m.familyMemberId)

    //console.log(evaluations)
    //console.log(evaluationMetas)

    const value = {
        scores: evaluations,
        scoresLookup,
        addScores: (scores) => linkOperation(evaluation_link, "bulk-add", scores),
        deleteScores: (scores) => linkOperation(evaluation_link, "bulk-delete", scores),

        valuationMeta: evaluationMetas,
        valuationMetaById,
        addValuationMetas: (metas) => linkOperation(evaluation_meta_link, "bulk-add", metas),
        deleteValuationMetas: (metas) => linkOperation(evaluation_meta_link, "bulk-delete", metas),

        loadAll: () => linkOperation(evaluation_link, "get").then(() => linkOperation(evaluation_meta_link, "get")) 
    }

    return (
        <ValuationsContext.Provider value={value}>
            {children}
        </ValuationsContext.Provider>
    )
}

export function useValuations() {
    return useContext(ValuationsContext)
}


export function useExcelReport() {
    const {t} = useTranslation()

    const {team} = useAuth()
    const {familyById} = usePatents()
    const {scoresLookup, valuationMetaById} = useValuations()

    const header = [
      'patentFamilyReference',
      'familyMemberReference',
      'evaluation-date',
      'technical',
      'commercial',
      'legal',
      'financial',
      'total',
    ].map(t)

    function toFixedNumber(s: number | undefined) {
        return (s && !isNaN(s)) ? Number(s.toFixed(1)) : ''
    }

    async function downloadExcel(groups: {header: Family | string, items: Member[]}[], scoreById: Record<number, number>) {
        // TODO: grouped by family
        const rows = groups.flatMap(({header, items}) => {
            const itemRows = items.map(m => {
                const family = familyById[m.patentFamilyId]
                const scores = scoresLookup[m.familyMemberId] ?? []
                const byType = ['technical', 'commercial', 'legal', 'financial'].map(type => scores.find(s => s.type === type)?.score).map(toFixedNumber)

                const total = byType.includes('') ? '' : toFixedNumber(scoreById[m.familyMemberId])

                const evaluationDate = valuationMetaById[m.familyMemberId]?.evaluationDate ?? ''

                return [
                    family.internalReference,
                    m.internalReference,
                    evaluationDate,
                    ...byType,
                    total,
                ]
            })
            let headerRow = []
            if (typeof header === 'object') {
                const familyTotal = toFixedNumber(_(itemRows).map(r => r[7]).filter(s => s !== '').map(Number).mean())
                headerRow = [[], [header.internalReference, '', '', '', '', '', '', familyTotal]]
            }
            return [...headerRow, ...itemRows]
        })
        const excel = {
            sheets: [
                {
                    name: "Evaluations",
                    header,
                    rows,
                }
            ]
        }
        return createExcelFile(excel, `Evaluation-Report-${team}-${new Date().toISOString().slice(0, 10)}.xlsx`)
    }

    return {downloadExcel}
}