import { useLocation } from "react-router-dom";
import { useRoles } from "../../user/Auth";
import { afterUpdateActionsStr } from ".";
import { patent_family } from "../../data";
import { OnlyRenameFolder } from "./AutomaticBackgroundChanges";
import { useFamily } from "../../patents/PatentsProvider";

export function FamilyChanged() {
  const {hasDocuments} = useRoles()
  const location = useLocation()
  const family = useFamily()

  if (!hasDocuments)
    return null

  const state = location.state ?? {}
  const changes = state[afterUpdateActionsStr] ?? []

  if (changes.length === 0 || family.patentFamilyId === undefined)
    return null

  // currently only move-folder is supported for families
  const doMoveFolder = changes.includes('move-folder')

  if (!doMoveFolder)
    return null

  return <FamilyChangedView {...state} />
}

function FamilyChangedView({from, to}) {
    return <OnlyRenameFolder {...{
        from: from.internalReference,
        to: to.internalReference,
        type: patent_family,
    }} />
}