import { useTranslation } from "react-i18next"
import { AgorumObject, AgorumObjectProvider, BrowserProvider } from "./DocumentsProvider"
import { FolderView} from "./DocumentFiles"
import { createSearchParams, useLocation, useSearchParams } from "react-router-dom"
import { FullPagePreview, RealPreview } from "./Preview"
import _ from "lodash"
import { IconSpinner } from "../components/icons"
import { useRef, useState } from "react"
import { addFolder, createFamilyFolder, findUuid, useFindUuid, useInvalidateFindUuid  } from "./backend"
import { htmlEncode } from "./encoding"
import { useDocumentSettings } from "./DocumentsSettings"
import { defaultPreviewProperties } from "../backend"
import clsx from "clsx"

function ExistingFocusedBrowser({uuid}: {uuid: string}) {
    const {t} = useTranslation()
    const location = useLocation()

    const [isResizing, setIsResizing] = useState(false)
    const folder = useRef(null)

    function onDrag(event) {
        if (isResizing) {
            event.preventDefault()
            const moveX = event.movementX
            folder.current.style.width = `${folder.current.offsetWidth + moveX}px`
        }
    }

    const [searchParams] = useSearchParams()

    const preview = searchParams.get('preview')

    function previewLink(object: Pick<AgorumObject, 'uuid'>) {
        const params = _.omit(searchParamsAsObject(searchParams), 'fullpage')
        const search = createSearchParams({...params, preview: object.uuid})
        return `${location.pathname}?${search}`
    }

    const fullpage = searchParams.get('fullpage') === 'true'

    const previewBackLink = `${location.pathname}?${createSearchParams(_.omit(searchParamsAsObject(searchParams), 'preview'))}`
    const fullpageLink = (object: AgorumObject) => `${previewLink(object)}&fullpage=true`

    return (
        <div>
            <h3>{t('documents')}</h3>
            <BrowserProvider {...{ previewLink, fullpageLink, previewBackLink, fullpageBackLink: previewLink({uuid: preview}) }}>
                <div className="flex flex-row gap-0" onMouseMove={onDrag}  onMouseUp={() => setIsResizing(false)}>
                    <div ref={folder} className={clsx(preview ? "max-sm:hidden" : "max-sm:block", "pt-2 pb-32 sm:min-w-md xl:min-w-lg")}>
                        <AgorumObjectProvider uuid={uuid}>
                            <FolderView defaultOpen hasSortButton />
                        </AgorumObjectProvider>
                    </div>
                    <div
                        onMouseDown={() => setIsResizing(true)}
                        className="max-sm:hidden group w-9 px-3 group cursor-col-resize"
                    >
                        <div className="w-2 h-full rounded-lg bg-slate-100 group-hover:bg-pcx-300" />
                    </div>
                    {preview &&
                        <div className="sticky top-0 py-2">
                            <AgorumObjectProvider uuid={preview} properties={defaultPreviewProperties}>
                                <RealPreview />
                            </AgorumObjectProvider>
                        </div>}
                    {fullpage && <FullPagePreview uuid={preview} />}
                </div>
            </BrowserProvider>
        </div>
    )
}

export function FocusedBrowser({entity, internalReference, parent, parentName}: {entity: string, internalReference: string, parent?: string, parentName?: string}) {
    const {number} = useDocumentSettings()
    //console.log({number, parent})
    const {uuid, isLoading} = useFindUuid(number, entity, internalReference, parent)
    //console.log({uuid, isLoading, number, internalReference})

    if (!number) return <div className="h-40" />
    if (uuid === undefined) return <CreatingNonExistentFolder {...{number, entity, internalReference, parent, parentName, isLoading}} />

    return <ExistingFocusedBrowser {...{uuid}} />
}

function searchParamsAsObject(searchParams: URLSearchParams) {
    const result = {}
    searchParams.forEach((v, k) => result[k] = v)
    return result
}

async function findParent(number: string, entity: string, parent: string, parentName?: string) {
    const parentFolder = await findUuid(number, entity, htmlEncode(parent))
    //console.log({parentFolder})
    if (parentFolder === undefined) {
        await createFamilyFolder({ number, entity, internalReference: htmlEncode(parent), description: parentName})
        return findParent(number, entity, parent)
    } else {
        return parentFolder
    }
}

function CreatingNonExistentFolder(
    {number, entity, internalReference, parent, parentName, isLoading}:
    {number: string, entity: string, internalReference: string, parent?: string, parentName?: string, isLoading: boolean}) {
    
    const {t} = useTranslation()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const invalidateFindUuid = useInvalidateFindUuid(entity, internalReference)
    
    async function createFolderHandler() {
        setIsSubmitting(true)
        try {
            if (parent === undefined) {
                //console.log('do parent undefined')
                await createFamilyFolder({ number, entity, internalReference, description: parentName })
                // TODO: invalidate Queries better
            } else {
                const existingParentFolder = await findParent(number, entity, parent, parentName)
                //console.log({existingParentFolder})
                await addFolder({ uuid: existingParentFolder, foldername: internalReference })
                //console.log({result})
            }
        } catch (error) {
            console.warn(error)
        } finally {
            invalidateFindUuid()
            setIsSubmitting(false)
        }
    }

    return (
        <div className="py-4">
            <h3 className="mb-2">{t('no-folder-yet')}</h3>
            <p className=" text-gray-800 pb-2">
                {t('click-to-create-folder', {name: internalReference})}
            </p>
            <button onClick={createFolderHandler} className="btn-primary whitespace-nowrap flex flex-row gap-2 items-center w-fit">
                <span>{t('create-folder')}</span> {(isSubmitting || isLoading) && <IconSpinner className="h-5 w-5 inline animate-spin text-white" />}
            </button>
        </div>
    )
}