import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { trade_mark } from '../data'
import { useRoles } from '../user/Auth'
import { useTasks } from '../tasks/TasksProvider'
import { deriveDate } from '../tasks/utils'
import Task from '../tasks/Task'

export default function TrademarkTasks({ trademarkId }: { trademarkId?: number }) {
  const { t } = useTranslation()

  const { tasksLookUp } = useTasks()

  const { isEditUser } = useRoles()

  const tasks = _(tasksLookUp[trade_mark]?.[trademarkId] ?? [])
    .filter(t => !t.done)
    .map(deriveDate)
    .sortBy(t => t.date)
    .value()

  const addButtonStyle = 'btn-secondary bg-white text-sm whitespace-nowrap py-0.5'

  return (
    <div>
      <div className="flex flex-row gap-2 justify-between mb-1 p-1 rounded-md last:mb-4 hover:bg-pcx-100">
        <h3>{t('tasks')}</h3>
        {isEditUser && (
          <Link title={t('add-task')} to={`task?entity=${trade_mark}&entityId=${trademarkId}`} className={addButtonStyle}>
            {t('add')}
          </Link>
        )}
      </div>
      <div>
        {tasks.map(task => (
          <Task key={task.taskId ?? -1} task={task} entity={trade_mark} entityId={trademarkId} />
        ))}
      </div>
    </div>
  )
}