import React, { createContext, ReactNode, useContext } from "react";
import _ from 'lodash'

import { Family, Member, OriginationLink } from "./patents";
import { useCrud, useLinkedCrud } from "../BackendProvider";
import { patent_family, family_member, orignation_link } from "../data";
import { isEqualOriginationLink } from "./utils";

const PatentsContext = createContext({
    families: [] as Family[],
    familyById: {} as Record<number, Family>,
    familyByReference: {} as Record<string, Family>,
    postFamily: (family: Family) => Promise.resolve(family),
    deleteFamily: (family: Family) => Promise.resolve({}),

    members: [] as Member[],
    memberById: {} as Record<number, Member>,
    memberByReference: {} as Record<string, Member>,
    membersByFamilyId: {} as Record<number, Member[]>,
    postMember: (member: Member) => Promise.resolve(member),
    deleteMember: (member: Member) => Promise.resolve({}),

    isLoading: false as boolean,
    reload: () => {},
    
    originationLinks: [] as OriginationLink[],
    postOriginationLink: (link: OriginationLink) => Promise.resolve(link) as Promise<OriginationLink | OriginationLink[]>,
    deleteOriginationLink: (link: OriginationLink) => Promise.resolve({}),
    parents: {} as Record<number, Member[]>,
});

export default function PatentsProvider({ children }: { children: React.ReactNode }) {

    const { data: families, postMutation: postFamily, deleteMutation: deleteFamily, isLoading: isLoadingFamilies, reload: reloadFamilies } = useCrud<Family>(patent_family, f => f.patentFamilyId)
    const { data: members, postMutation: postMember, deleteMutation: deleteMember, isLoading: isLoadingMembers, reload: reloadMembers } = useCrud<Member>(family_member, m => m.familyMemberId)

    const { data: originationLinks, postMutation: postOriginationLink, deleteMutation: deleteOriginationLink } = useLinkedCrud<OriginationLink>(orignation_link, isEqualOriginationLink)

    const familyById = _.keyBy(families, f => f.patentFamilyId)
    const familyByReference = _.keyBy(families, f => f.internalReference)

    const memberById = _.keyBy(members, m => m.familyMemberId)
    const memberByReference = _.keyBy(members, m => m.internalReference)
    const membersByFamilyId = _.groupBy(members, m => m.patentFamilyId)

    const isLoading = isLoadingFamilies || isLoadingMembers

    const parents = _(originationLinks)
        .groupBy(l => l.from)
        .mapValues(links => links.map(l => memberById[l.to]).filter(Boolean))
        .value()

    function reload() {
        reloadFamilies()
        reloadMembers()
    }

    const value = {
        families,
        familyById,
        familyByReference,
        postFamily,
        deleteFamily,

        members,
        memberById,
        memberByReference,
        membersByFamilyId,
        postMember,
        deleteMember,

        isLoading,
        reload,

        originationLinks,
        postOriginationLink,
        deleteOriginationLink,
        parents,
    }

    return <PatentsContext.Provider {...{value}} >
        {children}
    </PatentsContext.Provider>;
}

export function usePatents() {
    return React.useContext(PatentsContext)
}


const FamilyContext = createContext<Family | null>(null)

export function FamilyProvider({ family, children }: { family: Family, children: ReactNode }) {
    return <FamilyContext.Provider value={family}>{children}</FamilyContext.Provider>
}

export function useFamily() {
    return useContext(FamilyContext)
}


const MemberContext = createContext({
  member: undefined as Member,
  family: undefined as Family,
})

export function MemberProvider({member, family, children}) {
  return <MemberContext.Provider value={{family, member}}>{children}</MemberContext.Provider>
}

export function useMember() {
  return useContext(MemberContext)
}