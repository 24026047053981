import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { ChartBarIcon as SolidChartBarIcon } from '@heroicons/react/24/solid'

import { IconPlus } from "../components/icons"
import { useTrademarks } from './TrademarksProvider'
import { ActiveIpRightsChart, BarTreemapToggle, BasicProtectionChart, ChartContainer, CostDriverChart, CountryHistogram, TasksCard, TopXMarker } from '../cockpit/Graphs'
import _ from 'lodash'
import { useLocalState } from '../settings/localStorage'
import { Link } from 'react-router-dom'
import { useRoles } from '../user/Auth'
import { useSingleTeamMangement } from '../Management'
import { useFxContext } from '../forecast/FxProvider'
import { useCosts } from '../costs/CostsProvider'
import { accumulateCosts } from '../costs/utils'
import { useFilteredTrademarks } from '../filter/FilteredTrademarks'
import { colors, trade_mark } from '../data'
import { CostsGraph } from '../costs/CostsGraph'
import { useFilteredCommodities } from '../filter/FilteredCommodities'
import { useProducts } from '../products/ProductsProvider'

export default function TrademarkCockpit() {
  const { t } = useTranslation()

  const { isLoading } = useTrademarks()
  const {hasCosts, hasTasks, hasActiveChangesChart} = useRoles()

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>
          {t('cockpit')} | {t('trademarks')} | Patent Cockpit
        </title>
      </Helmet>
      <h1 className="sr-only">{t('cockpit')}</h1>

      <div className='main-content bg-pcx-100 pt-4'>
        {isLoading ? (
          <ChartContainer>
            <SolidChartBarIcon className="h-24 w-24 text-pcx-300 mx-auto my-12 animate-pulse" />
          </ChartContainer>
        ) : (
          <div className='flex flex-wrap gap-4'>
            <OverviewChart />
            <ActiveChangesChart />
            <TrademarkUsage />
            <ProductProtectionChart />
            <TrademarkCountries />
            {hasCosts && <CockpitCostChart />}
            {hasCosts && <TmCostDriversChart />}
            {hasTasks && <TasksCard />}
          </div>
        )}
      </div>
    </>
  )
}

function OverviewChart() {
    const {t} = useTranslation()
    const { trademarks, trademarkFamilies } = useFilteredTrademarks()
    const { commodities } = useFilteredCommodities()
    // const { membersByCommodityId, claimScopeMembersByCommodityId } = useProductMapping()
    // const { inventions } = useBackend()

    // TODO
    // const protectedCommodities = _(commodities).filter(c => !c.isThirdParty &&
    //     (c.commodityId in membersByCommodityId || c.commodityId in claimScopeMembersByCommodityId)
    // ).size()

    const values = [
        { count: trademarkFamilies.length, text: 'trademark-family', to: '/trademarks/portfolio' },
        { count: trademarks.length, text: 'trademark', side: t('registered') + ': ' + trademarks.filter(m => m.status === 'registered').length, to: '/trademarks/portfolio'},
        { count: commodities.filter(c => !c.isThirdParty).length, text: 'product', to: '/products'},
    ].filter(Boolean).map(({side, count, text, to}) => ({side, count, text: t(`cockpit-page.${text}`, {count}), to}))

    return (
        <ChartContainer>
            <h2 className='mb-2'><Link to="/trademarks/portfolio">{t('cockpit-page.overview')}</Link></h2>
            <table>
                <tbody>
                    {values.map(({count, text, side, to}) => {
                        const display = `${text} ${side ? `(${side})` : ''}`
                        return (
                        <tr key={text} >
                            <td className="pt-4 align-bottom text-right text-3xl font-bold text-pcx-600">{count}</td>
                            <td className="pl-2 align-bottom text-slate-400 font-base text-xl">
                                {to ? <Link to={to}>{display}</Link> : display}
                            </td>
                        </tr>
                    )}
                    )}
                </tbody>
            </table>
        </ChartContainer>
    )
}

// TODO: abandonement date
function ActiveChangesChart() {
    const {t} = useTranslation()
    const {trademarks} = useFilteredTrademarks()

    const [members, wrong] = _(trademarks)
        .map(t => ({start: t.registrationDate ?? t.applicationDate, end: t.renewalDate}))
        .partition(({start, end}) => start && end)
        .value()

    return (
        <ChartContainer>
            <div className="flex flex-row justify-between mb-2">
                <h2 className="mb-2 ">
                    <Link to="/trademarks/portfolio">{t('active-trademarks-changes')}</Link> 
                </h2>
            </div>
            <ActiveIpRightsChart {...{ members}} />
            {wrong.length > 0 &&
                <div className="text-slate-400 text-xs mt-2 text-right">{t('trademarks-excluded', {count: wrong.length})}</div>}
        </ChartContainer>
    )
}

function TrademarkUsage() {
    const {t} = useTranslation()
    const {trademarkFamilies} = useFilteredTrademarks()
    const {commodityById} = useProducts()
    const {trademarkProductMappingsByFamilyId} = useTrademarks()

    // TODO: make only use trademark if there are registered ones

    const maxCount = 3

    const protectionCount = _(trademarkFamilies).map(f => {
        const mappings = (trademarkProductMappingsByFamilyId[f.familyId] ?? []).filter(m => m.protected)
        const [thirdParty, own] = _.partition(mappings, m => commodityById[m.commodityId]?.isThirdParty)
        if (own.length > 0) {
            return Math.min(own.length, maxCount)
        } else if (thirdParty.length > 0) {
            return maxCount + 1 // Code for Only third party
        } else {
            return 0
        }
    }).countBy().value()
    
    const protectionColors = _.range(maxCount + 1).map(protectionCount => ({
        name: protectionCount === 0 ? "protects-none" : protectionCount >= maxCount ? `protects-${maxCount}-or-more` : `protects-${protectionCount}`,
        color: colors[`pcx-${(protectionCount + 2) * 100}`],
        protectionCount,
        displayName: protectionCount === 0 
            ? t("cockpit-page.protects-none") 
            : protectionCount >= maxCount 
            ? t("cockpit-page.protects-x-or-more", {count: maxCount}) 
            : t("cockpit-page.protects-x", {count: protectionCount}),
    }))
    protectionColors[maxCount + 1] = {
        name: "protects-from-competition",
        color: colors[`pcx-900`],
        protectionCount: maxCount + 1,
        displayName: t("cockpit-page.protects-from-competition") 
    }


    const protectionCounts = _(protectionCount)
        .toPairs()
        .map(([protectionCount, count]) => ({
            name: protectionColors[protectionCount]?.name,
            displayName: protectionColors[protectionCount]?.displayName,
            count,
            protectionCount,
        }))
        .value()

    return <ChartContainer>
        <div className="flex flex-col h-full">
            <h2 className='mb-4'><Link to="/trademarks/patent-mapping">{t('cockpit-page.trademark-protection-usage')}</Link></h2>
            <BasicProtectionChart {...{ protectionCounts, protectionColors }} />
            {/* <div className="grow" /> */}
            {/* {control} */}
        </div>
    </ChartContainer>
}

function ProductProtectionChart() {
    const {t} = useTranslation()
    const {trademarkProductMappingsByCommodityId} = useTrademarks()
    const {trademarkFamilyById} = useFilteredTrademarks()
    const {commodities} = useFilteredCommodities()

    //const {control, considerMember} = useConsiderMember('protection')

    if (commodities?.length === 0)
        return null

    const maxCount = 5
    const commodityProtection = _(commodities)
        .filter(c => !c?.isThirdParty)
        .map(c => {
            // TODO: filter by active families
            const families = _(trademarkProductMappingsByCommodityId[c.commodityId] ?? [])
                .map(f => trademarkFamilyById[f.familyId])
                .filter(Boolean)
                .uniqBy(f => f?.familyId)
                .size()

            return Math.min(families, maxCount)
        })
        .countBy()
        .value()
    //console.log({ commodityProtection })

    const protectionColors = _.range(maxCount + 1).map(protectionCount => ({
        name: protectionCount === 0 ? "no-protection" : protectionCount >= maxCount ? `protected-by-${maxCount}-or-more` : `protected-by-${protectionCount}`,
        color: colors[`pcx-${(protectionCount + 2) * 100}`],
        protectionCount,
        displayName: protectionCount === 0 
            ? t("cockpit-page.no-protection") 
            : protectionCount >= maxCount 
            ? t("cockpit-page.protected-by-x-or-more", {count: maxCount}) 
            : t("cockpit-page.protected-by-x", {count: protectionCount}),
    }))

    const protectionCounts = _(commodityProtection)
        .toPairs()
        .map(([protectionCount, count]) => ({
            name: protectionColors[protectionCount]?.name,
            displayName: protectionColors[protectionCount]?.displayName,
            count,
            protectionCount,
        }))
        .value()

    return <ChartContainer>
        <div className="flex flex-col h-full">
            <h2 className='mb-4'><Link to="/products/patent-mapping">{t('cockpit-page.product-protection-status')}</Link></h2>
            <BasicProtectionChart {...{ protectionCounts, protectionColors }} />
            {/* <div className="grow" />
            {control} */}
        </div>
    </ChartContainer>
}

function TrademarkCountries() {
    const {t} = useTranslation()

    const {trademarks} = useFilteredTrademarks()

    const [showBars, setShowBars] = useLocalState('tm-cockpit.show-bars', true)

    const data = _(trademarks).map(tm => tm.countryCode).countBy().map((count, name) => ({name, count})).sortBy(d => -d.count).value()

    return (
      <ChartContainer>
        <div className="flex flex-row justify-between">
            <h2 className="mb-2 ">
                <Link to="/trademarks/countries">{t('countries')}</Link> {showBars && <TopXMarker count={data.length} max={10} />}
            </h2>
            <div className=" mt-0.5">
                <BarTreemapToggle {...{showBars, setShowBars}} />
            </div>
        </div>
        <CountryHistogram {...{ data, showBars }} />
      </ChartContainer>
    )
}

function CockpitCostChart() {
    const {t} = useTranslation()

    const {team} = useSingleTeamMangement()
    const currency = team?.currency

    const {trademarks} = useFilteredTrademarks()
    const {fxConverter} = useFxContext()
    const {costsByEntity} = useCosts()

    const costs = trademarks.flatMap(m => costsByEntity[trade_mark]?.[m.trademarkId] ?? [])

    const byYear = _.mapValues(accumulateCosts(costs, {fxConverter, inclVat: false, currency}).byYear, c => Math.round(c))
    //console.log({byYear})

    return (
        <ChartContainer>
            <h2 className="pb-4">
                <Link to="/trademarks/costs">{t('costs')}</Link>
            </h2>
            <div className="hidden last:block">
                <p className="text-slate-500 mb-4">
                    {t('no-costs-yet')}
                </p>
                <Link to="/trademarks/costs" className="btn-secondary">
                    <IconPlus className="h-5 w-5 inline align-middle mb-1" /> {t('add-cost')} 
                </Link>
            </div>
            <CostsGraph {...{byYear}} />
        </ChartContainer>
    )
}

function TmCostDriversChart() {
    const {t} = useTranslation()

    const {team} = useSingleTeamMangement()
    const currency = team?.currency

    const {trademarksByFamilyId, trademarkFamilies} = useFilteredTrademarks()
    const {fxConverter} = useFxContext()
    const {costsByEntity} = useCosts()

    const [showBars, setShowBars] = useLocalState('cockpit-costs-show-bars', false)

    const costsByTrademarkId = costsByEntity[trade_mark] ?? {}

    const data = _(trademarkFamilies)
        .map(f => {
            const costs = (trademarksByFamilyId[f.familyId] ?? []).flatMap(m => costsByTrademarkId[m.trademarkId] ?? [])
            const total = Math.round(accumulateCosts(costs, { fxConverter, inclVat: false, currency }).total)
            return { name: f.reference, total }
        })
        .filter(d => d.total > 0)
        .sortBy('total')
        .reverse()
        .value()

    return (
        <ChartContainer>
            <div className="flex flex-row justify-between">
                <h2 className="mb-2 ">
                    <Link to="/trademarks/costs">{t('costs')}</Link> {showBars && <TopXMarker count={data.length} max={10} />}
                </h2>
                <div className=" mt-0.5">
                    <BarTreemapToggle {...{showBars, setShowBars}} />
                </div>
            </div>
            <CostDriverChart {...{data, showBars}} />
        </ChartContainer>
    )
}